import React, { useEffect, useRef, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import './styles.scss';

Chart.register(...registerables);

interface RadarChartProps {
  startAnimation: boolean;
}

const RadarChartComponent: React.FC<RadarChartProps> = ({ startAnimation }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null); // Use ref here instead of state
  const [activeGroupIndex, setActiveGroupIndex] = useState(0);

  useEffect(() => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;

    if (chartInstance.current) {
      chartInstance.current.destroy(); // Destroy the existing chart before creating a new one
    }

    // Create gradients
    const transparent = ctx.createLinearGradient(0, 0, 0, 150);
    transparent.addColorStop(0, 'rgba(255, 57, 57, 0)');
    transparent.addColorStop(1, 'rgba(255, 57, 57, 0)');

    const gradientRed = ctx.createLinearGradient(0, 0, 0, 150);
    gradientRed.addColorStop(0, 'rgba(255, 57, 57, 1)');
    gradientRed.addColorStop(1, 'rgba(255, 57, 57, 1)');

    const gradientBlue = ctx.createLinearGradient(0, 0, 0, 150);
    gradientBlue.addColorStop(0, 'rgba(80, 67, 251, 1)');
    gradientBlue.addColorStop(1, 'rgba(80, 67, 251, 1)');

    // Define dataset groups here, inside the useEffect to use the gradients
    const datasetGroups = [
      [
        {
          label: 'Month 1',
          data: [25, 60, 50, 45, 57, 42, 59, 30, 18, 38],
          fill: true,
          backgroundColor: '#6d618b80',
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointHitRadius: 50,
        },
        {
          label: 'Month 2',
          data: [30, 70, 52, 47, 60, 56, 62, 44, 24, 39],
          fill: true,
          backgroundColor: '#dd588bb8', // Apply gradient here
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointHitRadius: 50,
        },
      ],
      [
        {
          label: 'Month 1',
          data: [25, 60, 50, 45, 57, 42, 59, 30, 18, 38],
          fill: true,
          backgroundColor: '#6d618b80', // Apply gradient here
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointHitRadius: 50,
        },
        {
          label: '',
          data: [30, 70, 52, 47, 60, 56, 62, 44, 24, 39],
          fill: true,
          backgroundColor: transparent, // Apply gradient here
          borderColor: 'transparent',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointHoverBackgroundColor: 'transparent',
          pointHoverBorderColor: 'transparent',
          pointHitRadius: 50,
        },
      ],
    ];

    chartInstance.current = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: [
          'CARDIO',
          'STAMINA',
          'STRENGTH',
          'AGILITY',
          'POWER',
          'SPEED',
          'COORDINATION',
          'FLEXIBILITY',
          'BALANCE',
          'ACCURACY',
        ],
        datasets: datasetGroups[activeGroupIndex],
      },
      options: {
        plugins: {
          legend: {
            display: false, // Turns off the legend
          },
          tooltip: {
            enabled: false, // Disables tooltips
          },
        },
        animation: {
          duration: 1000, // Duration in milliseconds
          easing: 'easeOutBounce', // Easing function to use
        },
        scales: {
          r: {
            angleLines: {
              //display: false, // Controls the display of the angle lines (equivalent to gridLines.display)
            },
            ticks: {
              maxTicksLimit: 6,
              display: false, // Hides tick labels
            },
            grid: {
              //display: false, // Controls the display of the circular grid lines
            },
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) chartInstance.current.destroy();
    };
  }, [activeGroupIndex]);

  useEffect(() => {
    setActiveGroupIndex(0); // Always set activeGroupIndex to 0 on component mount
  }, []); // Empty dependency array to run only once on component mount

  useEffect(() => {
    setActiveGroupIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  }, [startAnimation]);

  return (
    <div className="graph">
      <canvas ref={canvasRef} width="600" height="400"></canvas>
    </div>
  );
};

export default RadarChartComponent;
