import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Nav from '../../features/nav';
import Home from '../home';
import LostPage from '../lost-page';
import './styles.scss';

const Root = () => {
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<LostPage />} />
      </Routes>
      {/* <Socials /> */}
    </Router>
  );
};

export default Root;
