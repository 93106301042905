import { NavLink } from 'react-router-dom';
import './styles.scss';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';

const Nav = () => {
  const controls = useAnimation();
  const wiggleSequence = async () => {
    await controls.start({ rotate: -10, transition: { duration: 0.1 } });
    await controls.start({ rotate: 10, transition: { duration: 0.1 } });
    await controls.start({ rotate: -10, transition: { duration: 0.1 } });
    await controls.start({ rotate: 10, transition: { duration: 0.1 } });
    controls.start({ rotate: 0, transition: { duration: 0.1 } });
  };
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const divElement = document.getElementById('scrollable-area');
      if (divElement) {
        const position = divElement.scrollTop;
        setScrollPosition(position);
      }
    };

    handleScroll(); // Call handleScroll initially to set the initial scroll position

    const divElement = document.getElementById('scrollable-area');
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleNavLinkClick = () => {
    const divElement = document.getElementById('scrollable-area');
    if (divElement) {
      divElement.scrollTo({
        top: 0,
        behavior: 'smooth', // Optionally, you can add smooth scrolling behavior
      });
    }
  };

  return (
    <nav
      className="nav"
      style={{
        backgroundColor: scrollPosition > 300 ? '#0a0a0a' : 'transparent',
        transition: 'background-color 0.3s ease',
      }}
    >
      <motion.div
        className="nav__logo"
        whileHover={{
          scale: 1.4,
          rotate: -6,
        }}
        animate={controls}
        onTap={wiggleSequence}
        transition={{
          duration: 0.2,
          ease: 'easeInOut',
        }}
      >
        <NavLink to="/" onClick={handleNavLinkClick}>
          <svg
            fill="#ffffff"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
          >
            <path d="M 24 2 A 4 4 0 0 0 24 10 A 4 4 0 0 0 24 2 z M 21.007812 11.5 C 18.523528 11.5 16.508072 13.661523 16.683594 16.140625 C 16.683689 16.141925 16.683498 16.143231 16.683594 16.144531 L 17.363281 26.5 L 11 26.5 L 11 23 C 11 22.448 10.552 22 10 22 L 7 22 C 6.448 22 6 22.448 6 23 L 6 26.5 A 1.50015 1.50015 0 1 0 6 29.5 L 6 33 C 6 33.552 6.448 34 7 34 L 10 34 C 10.552 34 11 33.552 11 33 L 11 29.5 L 17.757812 29.5 A 2.0002 2.0002 0 0 0 18.9375 30.445312 L 17.511719 43.279297 A 2.0004927 2.0004927 0 1 0 21.488281 43.720703 L 21.550781 43.162109 L 24.125 29.527344 L 26.972656 42.121094 L 27.224609 43.796875 A 2.0002 2.0002 0 1 0 31.179688 43.203125 L 29.251953 30.351562 A 2.0003788 2.0003788 0 0 0 30.230469 29.5 L 37 29.5 L 37 33 C 37 33.552 37.448 34 38 34 L 41 34 C 41.552 34 42 33.552 42 33 L 42 29.5 A 1.50015 1.50015 0 1 0 42 26.5 L 42 23 C 42 22.448 41.552 22 41 22 L 38 22 C 37.448 22 37 22.448 37 23 L 37 26.5 L 30.636719 26.5 L 31.318359 16.132812 L 31.316406 16.144531 C 31.494567 13.663793 29.478798 11.5 26.992188 11.5 L 21.007812 11.5 z" />
          </svg>
        </NavLink>
      </motion.div>
    </nav>
  );
};

export default Nav;
