import useDocumentTitle from '../../features/pageTitle/pageTitle';
import './styles.scss';
import { motion } from 'framer-motion';
import Kevin from '../../assets/kevin.png';
import SectionVideo from '../../assets/section-video.mp4';

function LostPage() {
  useDocumentTitle('You lost?');

  return (
    <main className="lost-page">
      <motion.div
        initial={{
          y: -600,
        }}
        animate={{
          y: 0,
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 40,
          repeat: Infinity,
          repeatType: 'mirror',
          repeatDelay: 4,
          delay: 2,
        }}
      >
        <img className="section-image" alt="Kevin" src={Kevin} />
      </motion.div>
      <div className="scroll-container">
        <section>
          <div className="section-content">
            <h2>Page Not Found</h2>
          </div>
          <div className="video-container">
            <div className="video-overlay" />
            <video
              autoPlay={true}
              loop={true}
              controls={false}
              muted
              playsInline
            >
              <source src={SectionVideo} type="video/mp4"></source>
            </video>
          </div>
        </section>
      </div>
    </main>
  );
}

export default LostPage;
