import './styles.scss';
import { motion, useAnimation } from 'framer-motion';

const ITEMS = [
  {
    name: 'Cardio',
    path: (
      <path d="M 33.998047 6 C 31.790047 6 30 7.7920469 30 9.9980469 C 30 12.208047 31.790047 14 33.998047 14 C 36.211047 14 38 12.208047 38 9.9980469 C 38 7.7920469 36.211047 6 33.998047 6 z M 17.435547 12.5 C 16.248955 12.5 15.1222 13.028751 14.363281 13.939453 L 9.9628906 19.21875 A 2.0010763 2.0010763 0 1 0 13.037109 21.78125 L 17.332031 16.625 L 22.378906 17.191406 L 17.792969 23.632812 C 17.301817 24.296979 17 25.109501 17 25.998047 C 17 27.458138 17.790049 28.722923 18.957031 29.421875 L 18.955078 29.425781 L 27.212891 33.851562 L 28.5 42.40625 L 28.5 42.5 A 2.0002 2.0002 0 1 0 32.5 42.5 L 32.5 31.5 A 2.0002 2.0002 0 0 0 31.550781 29.798828 L 25.699219 26.185547 L 29.175781 21.212891 L 30.6875 23.34375 L 30.705078 23.380859 A 2.0002 2.0002 0 0 0 32.5 24.5 L 39.5 24.5 A 2.0002 2.0002 0 1 0 39.5 20.5 L 33.746094 20.5 L 31.162109 15.236328 A 2.0002 2.0002 0 0 0 31.083984 15.091797 C 30.351058 13.863726 29.165918 13.115261 28.111328 12.802734 C 27.056738 12.490208 26.114364 12.5 25.427734 12.5 L 17.435547 12.5 z M 17.246094 30.814453 L 15.978516 33.724609 L 8.9414062 34.5 L 8.5 34.5 A 2.0002 2.0002 0 1 0 8.5 38.5 L 17.5 38.5 A 2.0002 2.0002 0 0 0 19.423828 37.130859 L 21.849609 33.085938 C 20.677609 32.606938 18.403094 31.404453 17.246094 30.814453 z" />
    ),
  },
  {
    name: 'Stamina',
    path: (
      <path d="M 24.5 4 A 6.5 6.5 0 0 0 24.5 17 A 6.5 6.5 0 0 0 24.5 4 z M 20.617188 20 L 24.5 27.763672 L 28.382812 20 L 20.617188 20 z M 18.390625 20.019531 C 17.889625 20.064531 17.424766 20.218078 17.009766 20.455078 C 16.766766 20.562078 16.531219 20.699672 16.324219 20.888672 L 6.8378906 29.541016 C 6.8128906 29.563016 6.7876719 29.587328 6.7636719 29.611328 C 5.2156719 31.144328 5.0717344 33.588016 6.4277344 35.291016 C 6.4597344 35.332016 6.4932969 35.371156 6.5292969 35.410156 L 12.644531 42.177734 C 13.138531 42.723734 13.818953 43 14.501953 43 C 15.099953 43 15.698734 42.786516 16.177734 42.353516 C 17.201734 41.427516 17.281469 39.846266 16.355469 38.822266 L 16.335938 38.802734 L 12.505859 32.947266 L 16.226562 30.273438 L 17.835938 41.822266 C 17.929937 42.497266 18.507453 43 19.189453 43 L 29.810547 43 C 30.492547 43 31.070062 42.496313 31.164062 41.820312 L 32.763672 30.349609 L 36.537109 33.074219 L 32.597656 38.765625 L 32.591797 38.771484 C 31.665797 39.795484 31.746484 41.376734 32.771484 42.302734 C 33.250484 42.734734 33.848313 42.947266 34.445312 42.947266 C 35.128312 42.947266 35.809734 42.669047 36.302734 42.123047 L 42.365234 35.410156 C 42.399234 35.371156 42.433797 35.332016 42.466797 35.291016 C 43.822797 33.588016 43.677172 31.145547 42.076172 29.560547 L 32.882812 20.970703 C 32.602812 20.709703 32.276547 20.532828 31.935547 20.423828 C 31.534547 20.204828 31.088375 20.062531 30.609375 20.019531 L 25.117188 31 L 26.025391 31 C 26.563391 31 27 31.435656 27 31.972656 L 27 35.025391 C 27 35.563391 26.564344 36 26.027344 36 L 22.974609 36 C 22.436609 36 22 35.564344 22 35.027344 L 22 31.974609 C 22 31.436609 22.435656 31 22.972656 31 L 23.880859 31 L 18.390625 20.019531 z" />
    ),
  },
  {
    name: 'Strength',
    path: (
      <path d="M 24 2 A 4 4 0 0 0 24 10 A 4 4 0 0 0 24 2 z M 21.007812 11.5 C 18.523528 11.5 16.508072 13.661523 16.683594 16.140625 C 16.683689 16.141925 16.683498 16.143231 16.683594 16.144531 L 17.363281 26.5 L 11 26.5 L 11 23 C 11 22.448 10.552 22 10 22 L 7 22 C 6.448 22 6 22.448 6 23 L 6 26.5 A 1.50015 1.50015 0 1 0 6 29.5 L 6 33 C 6 33.552 6.448 34 7 34 L 10 34 C 10.552 34 11 33.552 11 33 L 11 29.5 L 17.757812 29.5 A 2.0002 2.0002 0 0 0 18.9375 30.445312 L 17.511719 43.279297 A 2.0004927 2.0004927 0 1 0 21.488281 43.720703 L 21.550781 43.162109 L 24.125 29.527344 L 26.972656 42.121094 L 27.224609 43.796875 A 2.0002 2.0002 0 1 0 31.179688 43.203125 L 29.251953 30.351562 A 2.0003788 2.0003788 0 0 0 30.230469 29.5 L 37 29.5 L 37 33 C 37 33.552 37.448 34 38 34 L 41 34 C 41.552 34 42 33.552 42 33 L 42 29.5 A 1.50015 1.50015 0 1 0 42 26.5 L 42 23 C 42 22.448 41.552 22 41 22 L 38 22 C 37.448 22 37 22.448 37 23 L 37 26.5 L 30.636719 26.5 L 31.318359 16.132812 L 31.316406 16.144531 C 31.494567 13.663793 29.478798 11.5 26.992188 11.5 L 21.007812 11.5 z" />
    ),
  },
  {
    name: 'Agility',
    path: (
      <path d="M 37.958984 2.984375 A 2.0002 2.0002 0 0 0 36.890625 3.3359375 L 26.066406 10.550781 L 22.498047 12.810547 L 19 13 L 6 13 A 2.0002 2.0002 0 1 0 6 17 L 6.1269531 17 L 19 18.185547 L 19 27.5 L 19 28.447266 L 19 43 A 2.0002 2.0002 0 1 0 23 43 L 23 41.855469 L 24.042969 28.998047 L 41.345703 25.970703 A 2.0002 2.0002 0 0 0 40.992188 21.996094 A 2.0002 2.0002 0 0 0 40.654297 22.029297 L 39.871094 22.166016 L 27.761719 22.802734 L 27.287109 15.464844 L 38.902344 6.8027344 L 39.109375 6.6640625 A 2.0002 2.0002 0 0 0 37.958984 2.984375 z M 30 30.501953 A 4 4 0 0 0 30 38.501953 A 4 4 0 0 0 30 30.501953 z" />
    ),
  },
  {
    name: 'Power',
    path: (
      <path d="M 18.5 5 C 14.91 5 12 7.91 12 11.5 C 12 14.573012 14.134221 17.140517 17 17.818359 L 17 27 A 2.0002 2.0002 0 0 0 17.001953 27.072266 A 4 4 0 0 0 17.007812 27.183594 A 2.0002 2.0002 0 0 0 17.009766 27.208984 A 4 4 0 0 0 21 31 L 33 31 L 39.005859 31.648438 L 40 40.677734 L 40.078125 40.582031 A 2.0002 2.0002 0 0 0 44 40 L 44 29 A 2.0002 2.0002 0 0 0 42.302734 27.025391 C 37.360105 25.067731 27.852861 23.300167 22.287109 23.050781 L 21.324219 17.345703 C 23.49688 16.293788 25 14.075965 25 11.5 C 25 7.91 22.09 5 18.5 5 z M 18.5 10 C 19.328 10 20 10.672 20 11.5 C 20 12.328 19.328 13 18.5 13 C 17.672 13 17 12.328 17 11.5 C 17 10.672 17.672 10 18.5 10 z M 10 23 A 4 4 0 0 0 10 31 A 4 4 0 0 0 10 23 z M 7.5 34 A 1.50015 1.50015 0 1 0 7.5 37 L 35.5 37 A 1.50015 1.50015 0 1 0 35.5 34 L 7.5 34 z" />
    ),
  },
  {
    name: 'Speed',
    path: (
      <path d="M 22.486328 7.5 A 2.0002 2.0002 0 0 0 21.507812 7.7636719 L 14.507812 11.763672 A 2.0002 2.0002 0 1 0 16.492188 15.236328 L 22.205078 11.970703 L 24.595703 15.318359 L 17.958984 20.576172 C 16.790743 21.274522 16 22.539652 16 23.998047 C 16 25.0266 16.399974 25.955104 17.037109 26.664062 L 17.027344 26.675781 L 17.132812 26.78125 C 17.161493 26.810844 17.191129 26.838489 17.220703 26.867188 L 21.367188 31 L 15 31 A 2.0002 2.0002 0 1 0 15 35 L 26.5 35 A 2.0002 2.0002 0 0 0 28.238281 32.011719 L 24.482422 25.402344 L 29.552734 21.978516 L 32.615234 26.197266 L 32.699219 26.371094 A 2.0002 2.0002 0 0 0 34.5 27.5 L 41.5 27.5 A 2.0002 2.0002 0 1 0 41.5 23.5 L 35.753906 23.5 L 32.054688 15.863281 A 2.0002 2.0002 0 0 0 31.972656 15.708984 C 31.462601 14.85436 30.854927 14.299627 30.044922 13.509766 C 29.234917 12.719904 28.276604 11.849968 27.351562 11.03125 C 25.501481 9.3938142 23.777344 7.9609375 23.777344 7.9609375 A 2.0002 2.0002 0 0 0 22.486328 7.5 z M 35.998047 8 C 33.790047 8 32 9.7920469 32 11.998047 C 32 14.208047 33.790047 16 35.998047 16 C 38.211047 16 40 14.208047 40 11.998047 C 40 9.7920469 38.211047 8 35.998047 8 z M 7 17 A 1.0001 1.0001 0 1 0 7 19 L 14 19 A 1.0001 1.0001 0 1 0 14 17 L 7 17 z M 4 22 A 1.0001 1.0001 0 1 0 4 24 L 11 24 A 1.0001 1.0001 0 1 0 11 22 L 4 22 z M 5 27 A 1.0001 1.0001 0 1 0 5 29 L 12 29 A 1.0001 1.0001 0 1 0 12 27 L 5 27 z M 20.375 37 L 17.900391 40.300781 C 17.237391 41.184781 17.417781 42.437609 18.300781 43.099609 C 18.660781 43.370609 19.080047 43.5 19.498047 43.5 C 20.106047 43.5 20.707563 43.223219 21.101562 42.699219 L 25.376953 37 L 20.375 37 z" />
    ),
  },
  {
    name: 'Coordination',
    path: (
      <path d="M 22.998047 5 C 20.790047 5 19 6.7920469 19 8.9980469 C 19 11.208047 20.790047 13 22.998047 13 C 25.211047 13 27 11.208047 27 8.9980469 C 27 6.7920469 25.211047 5 22.998047 5 z M 19.996094 15 A 2.0002 2.0002 0 0 0 19.5625 15.044922 C 18.04664 15.211433 16.791506 16.211008 16.269531 17.587891 L 16.244141 17.585938 L 11.990234 27.216797 C 11.989604 27.218141 11.988911 27.219358 11.988281 27.220703 C 11.987388 27.222611 11.987218 27.224654 11.986328 27.226562 L 11.970703 27.261719 L 11.972656 27.263672 C 11.056475 29.280733 11.92963 31.659245 13.935547 32.597656 C 14.256798 32.747965 14.58865 32.837081 14.921875 32.898438 L 14.919922 32.904297 L 15.021484 32.912109 C 15.063755 32.9188 15.106132 32.924369 15.148438 32.929688 A 2.0002 2.0002 0 0 0 15.701172 33 L 16.169922 33 L 23.669922 33.574219 L 22.251953 38.25 L 20.683594 41.664062 A 2.0002 2.0002 0 1 0 24.316406 43.335938 L 29.603516 31.835938 A 2.0002 2.0002 0 0 0 28.261719 29.060547 L 28.265625 29.052734 L 20.826172 27.021484 L 23.919922 20.097656 L 37.238281 19 L 39 19 A 2.0002 2.0002 0 1 0 39 15 L 20 15 C 20 15 19.998047 15 19.998047 15 C 19.998047 15 19.996094 15 19.996094 15 z" />
    ),
  },
  {
    name: 'Flexibility',
    path: (
      <path d="M 19.470703 6.4726562 A 2.0002 2.0002 0 0 0 17.5 8.5 L 17.5 21.957031 C 17.5 23.758084 17.209776 25.545558 16.640625 27.253906 L 12.603516 39.367188 A 2.0002 2.0002 0 1 0 16.396484 40.632812 L 16.722656 39.652344 L 21.515625 29 L 32.025391 29 L 37.544922 42.925781 L 37.558594 42.984375 A 2.0009189 2.0009189 0 1 0 41.441406 42.015625 L 36.955078 24.027344 C 36.925145 23.853247 36.876632 23.687679 36.826172 23.521484 A 2.0002 2.0002 0 0 0 36.779297 23.373047 C 36.205384 21.706856 34.64088 20.5 32.775391 20.5 C 32.681172 20.5 32.592687 20.521302 32.5 20.527344 L 32.5 20.5 L 22.701172 20.5 L 21.5 9.0996094 L 21.5 8.5 A 2.0002 2.0002 0 0 0 19.470703 6.4726562 z M 11.498047 20.5 C 9.2900469 20.5 7.5 22.292047 7.5 24.498047 C 7.5 26.708047 9.2900469 28.5 11.498047 28.5 C 13.711047 28.5 15.5 26.708047 15.5 24.498047 C 15.5 22.292047 13.711047 20.5 11.498047 20.5 z M 25.621094 31 L 22.939453 42.027344 C 22.678453 43.100344 23.336156 44.182359 24.410156 44.443359 C 24.569156 44.482359 24.728766 44.5 24.884766 44.5 C 25.785766 44.5 26.603172 43.886656 26.826172 42.972656 L 27.101562 41.841797 L 31.195312 31 L 25.621094 31 z" />
    ),
  },
  {
    name: 'Balance',
    path: (
      <path d="M 12.998047 12 C 10.790047 12 9 13.792047 9 15.998047 C 9 18.208047 10.790047 20 12.998047 20 C 15.211047 20 17 18.208047 17 15.998047 C 17 13.792047 15.211047 12 12.998047 12 z M 30.658203 13.998047 A 2.0002 2.0002 0 0 0 29.759766 14.240234 L 13.705078 22.691406 C 13.16908 22.898758 12.664871 23.212082 12.232422 23.644531 C 10.637993 25.239922 10.593527 27.783546 12.068359 29.451172 L 12.042969 29.488281 L 20.226562 37.644531 C 20.228909 37.646889 20.230068 37.64999 20.232422 37.652344 C 20.234773 37.654697 20.237879 37.655857 20.240234 37.658203 L 20.412109 37.830078 L 20.429688 37.8125 C 20.504054 37.878518 20.580497 37.9401 20.658203 38 L 10.5 38 A 1.50015 1.50015 0 1 0 10.5 41 L 36.5 41 A 1.50015 1.50015 0 1 0 36.5 38 L 26.554688 38 L 43.21875 23.744141 A 2.0002 2.0002 0 0 0 42.035156 20.207031 A 2.0002 2.0002 0 0 0 40.617188 20.705078 L 40.564453 20.75 L 24.878906 30.277344 L 19.998047 25.396484 L 30.951172 18.134766 L 31.623047 17.78125 A 2.0002 2.0002 0 0 0 30.658203 13.998047 z" />
    ),
  },
  {
    name: 'Accuracy',
    path: (
      <path d="M 24 1 A 4 4 0 0 0 24 9 A 4 4 0 0 0 24 1 z M 9.5 11 A 1.50015 1.50015 0 1 0 9.5 14 L 11.550781 14 L 12.519531 20.783203 A 2.0002 2.0002 0 0 0 15.664062 22.123047 L 19.419922 20.123047 L 19.755859 25.861328 L 19.603516 26.845703 A 2.0002 2.0002 0 0 0 19.523438 27.189453 L 18.046875 36.597656 A 2.0002 2.0002 0 0 0 18.101562 37.460938 L 20.578125 46.054688 A 2.0003205 2.0003205 0 1 0 24.421875 44.945312 L 23.685547 42.394531 L 29.662109 38.126953 A 2.0002 2.0002 0 0 0 30.396484 35.867188 L 30.285156 35.535156 L 28.195312 26.666016 L 28.550781 20.634766 L 31.285156 22.087891 A 2.0002 2.0002 0 0 0 34.423828 21.048828 L 36.423828 14.048828 A 2.0005815 2.0005815 0 0 0 36.435547 14 L 39.5 14 A 1.50015 1.50015 0 1 0 39.5 11 L 9.5 11 z M 15.591797 14 L 19.015625 14 L 15.964844 16.611328 L 15.591797 14 z M 28.839844 14 L 32.277344 14 L 31.513672 16.673828 L 28.9375 14.087891 A 2.0002 2.0002 0 0 0 28.839844 14 z M 23.958984 30.802734 L 26.042969 35.455078 L 26.134766 35.730469 L 22.703125 38.183594 L 22.398438 36.511719 L 23.958984 30.802734 z" />
    ),
  },
];

const Domains = () => {
  const controls = useAnimation();

  const wiggleSequence = async () => {
    await controls.start({ rotate: -10, transition: { duration: 0.1 } });
    await controls.start({ rotate: 10, transition: { duration: 0.1 } });
    await controls.start({ rotate: -10, transition: { duration: 0.1 } });
    await controls.start({ rotate: 10, transition: { duration: 0.1 } });
    controls.start({ rotate: 0, transition: { duration: 0.1 } });
  };

  return (
    <div className="domain-items">
      {ITEMS.map((item, index) => (
        <motion.div
          key={index}
          className="domain-item"
          whileHover={{ scale: 1.6, rotate: 10 }}
          animate={controls}
          onTap={wiggleSequence}
        >
          <div className="domain-item__image">
            <svg
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
            >
              {item.path}
            </svg>
          </div>
          <h4>{item.name}</h4>
        </motion.div>
      ))}
    </div>
  );
};

export default Domains;
