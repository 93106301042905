import useDocumentTitle from '../../features/pageTitle/pageTitle';
import SectionVideo from '../../assets/section-video.mp4';
import './styles.scss';
import { motion } from 'framer-motion';
import Domains from '../../features/domain-item/index';
import RadarChartComponent from '../../features/graph';
import { useEffect, useState } from 'react';
import Email from '../../features/email';

const Root = () => {
  useDocumentTitle('Home');
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      toggleAnimation();
    }, 3000); // Change dataset every 3000ms (3 seconds)

    const toggleAnimation = () => {
      setStartAnimation(!startAnimation);
    };

    return () => clearInterval(intervalId); // Clear the interval when the component unmounts or the index change
  }, [startAnimation]);

  return (
    <main className="home-page">
      <div className="scroll-container" id="scrollable-area">
        <section className="section-content section-content__hero">
          <div className="section-title section-title__hero">
            <h2>Functional Fitness</h2>
            <p>
              Helping <b>you</b> understand where <b>your</b> fitness levels are
              so <b>you</b> can focus on <b>growth</b> in{' '}
              <b>every functional domain</b>.
            </p>
            <div>
              <div className="coming-soon">
                <p>
                  Coming Soon: <b>Sign up</b> to be notified when launched.
                </p>
                <Email />
              </div>
            </div>
          </div>
          <div className="video-container">
            <div className="video-overlay" />
            <video
              autoPlay={true}
              loop={true}
              controls={false}
              muted
              playsInline
            >
              <source src={SectionVideo} type="video/mp4"></source>
            </video>
          </div>
        </section>
        <section className="section-content section-content__ten-domains">
          <div className="section-title">
            <h2>The 10</h2>
            <h3>Functional Domains</h3>
            <p>
              A <b>comprehensive framework</b> for assessing and improving{' '}
              <b>your</b> fitness level
            </p>
          </div>
          <div>
            <Domains />
          </div>
        </section>
        <section className="section-content section-content__graph">
          <div className="section-title">
            <h2>Map Your Performance</h2>
            <motion.h3
              initial={{
                y: 100,
                opacity: 0,
              }}
              animate={{
                y: startAnimation ? 0 : 100,
                opacity: startAnimation ? 1 : 0,
              }}
              transition={{
                type: 'spring',
                damping: 40,
                stiffness: 400,
                duration: 0.25,
              }}
            >
              and growth
            </motion.h3>
          </div>
          <div className="legend">
            <div className="legend__item legend__item--month1">
              <p>Month 1</p>
            </div>
            <div className="legend__item legend__item--month2">
              <p>Month 2</p>
            </div>
          </div>
          <div>
            <RadarChartComponent startAnimation={startAnimation} />
          </div>
        </section>
      </div>
    </main>
  );
};

export default Root;
