import './styles.scss';

const Email = () => {
  return (
    <div className="signup" id="mc_embed_shell">
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
        rel="stylesheet"
        type="text/css"
      />
      <style type="text/css">
        {`
        #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}
      `}
      </style>
      <div id="mc_embed_signup">
        <form
          action="https://fitness.us22.list-manage.com/subscribe/post?u=9c0ed05ca7d1075d794deda20&amp;id=e8da705323&amp;f_id=00ccc3e1f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div className="signup__form" id="mc_embed_signup_scroll">
            <input
              type="email"
              name="EMAIL"
              className="required email"
              id="mce-EMAIL"
              required
              placeholder="Email Address"
            />

            <div
              aria-hidden="true"
              style={{ position: 'absolute', left: '-5000px' }}
            >
              {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
              <input
                type="text"
                name="b_9c0ed05ca7d1075d794deda20_e8da705323"
                tabIndex={-1}
                value=""
              />
            </div>
            <input
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
              value="Subscribe"
            />
          </div>
          <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
          <div id="mce-responses" className="clear foot">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            ></div>
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script type="text/javascript">
        {`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(window.jQuery));var $mcj = window.jQuery.noConflict(true);`}
      </script>
    </div>
  );
};

export default Email;
